<template>
    <div ref="container" class="mt-n3 mx-n3">
        <v-card :loading="loading" elevation="0">
            <v-card-title ref="title">
                <h3>RESERVES</h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="mt-n5">
                <v-data-table
                    id="virtual-scroll-table"
                    :headers="headers"
                    :items="items"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    :style="`height:${this.height}px`"
                    calculate-widths
                >
                    <!--ITEMS-->
                    <template v-slot:[`item.project_name`]="{ item }">
                        <p class="my-0">
                            {{
                                item.project_name == ''
                                    ? 'Free'
                                    : item.project_name
                            }}
                        </p>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <p
                            v-if="
                                item.price != undefined && item.price != 'NaN'
                            "
                            class="my-0"
                        >
                            $ {{ item.price }}
                        </p>
                    </template>
                    <template v-slot:[`item.usable_free_value`]="{ item }">
                        <p class="my-0">$ {{ item.usable_free_value }}</p>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'ProjectRequested',
    props: {
        projectId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        height: 0,
        items: [],
        project: {},
        loading: false,
        headers: [
            {
                text: 'PROJECT',
                value: 'project_name',
                align: 'left',
                sortable: false,
            },
            {
                text: 'ITEM',
                value: 'short_material_text',
                align: 'left',
                sortable: false,
            },
            {
                text: 'STORE',
                value: 'storage',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'free_use',
                align: 'left',
            },
            {
                text: 'PRICE (UNIT)',
                value: 'price',
                align: 'left',
            },
            {
                text: 'TOTAL PRICE',
                value: 'usable_free_value',
                align: 'left',
            },
        ],
    }),
    async mounted() {
        try {
            this.loading = true
            this.project = await API.getProject(this.projectId)
            await this.getStock()
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                title: { clientHeight: titleHeight },
            } = this.$refs
            this.height =
                window.innerHeight - containerHeight - titleHeight - 130
        },
        numberFormat(amount) {
            return new Intl.NumberFormat('de-DE').format(Math.ceil(amount))
        },
        async getStock() {
            try {
                const stock = await API.getStock()
                for (const element of stock) {
                    element.items.forEach(item => {
                        if (
                            (item.order || item.orderNumber) &&
                            this.project.sapCode &&
                            (item.order || item.orderNumber) ==
                                this.project.sapCode
                        ) {
                            this.items.push(item)
                        }
                    })
                }
                this.items.forEach(item => {
                    item.available = this.numberFormat(item.available)
                    item.reserved = this.numberFormat(item.reserved)
                    item.price = this.numberFormat(
                        Number(item.usable_free_value) / Number(item.free_use)
                    )
                    item.usable_free_value = this.numberFormat(
                        Number(item.usable_free_value)
                    )
                    item.total = this.numberFormat(item.total)
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>

<style></style>
